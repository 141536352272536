<template>
  <div>
    <hr>
    <d-row lg="12">
      <d-col class="col-lg-12">

        <div class="statistics__info mt-5 mb-3">
              <strong>{{ $t('statistics.Capacity_Overview') }} </strong><br/>
              {{ $t('statistics.Avalability_per_service') }}
        </div>
   

        <ul class="nav nav-tabs" id="myTab" role="tablist">
        <template v-for="serviceLocation in serviceLocations">
            <li :key="serviceLocation.id" class="nav-item" @click="setTabID(serviceLocation.id)">
             <a class="nav-link cursor-pointer" :class="[ serviceLocation.id ==tabID && 'active' ]"   data-toggle="tab" role="button" aria-selected="true">
                {{ convertServiceLocationTypeToString(serviceLocation.parking_type_id) }}
              </a>
            </li>
        </template>
      </ul>
      </d-col>
    </d-row>  
    <d-row lg="12"> 
      <d-col class="col-lg-12">
        <div v-if="serviceLocations.length === 0" class="provider-page__no-content">
          Has no Service Location yet
        </div>
        <div v-else>
          <service-location :key="tabID" :tabID="tabID" :service-location="serviceLocations.find(item=>item.id==tabID)" />
        </div>
      </d-col>
    </d-row>

  </div>

</template>

<script>
import {mapProviderStateFields, } from "~/store";
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";
	import ServiceLocation from "@/components/ServiceLocation";
  import Vue from "vue";
  export default {
		name: "ServiceLocations",
    components: {ServiceLocation},
    mixins: [graphqlCalls, mixins],

    props: {
   
      startDate:Date,
      endDate:Date,
      labels: Array
    },
data(){
  return {
   
    date:new Date().toISOString().split('T')[0]+'T23:59:59Z',
    serviceLocations :[],
    tabID:''
  }
},
  computed: {
    ...mapProviderStateFields([
      'active_provider_index',
      'providers',
    ]),

    provider : function () {
      if(this.providers.length === 0) return null

      return this.providers[this.active_provider_index];

    },
  },
  mounted() {
    if(this.provider) {
    this.loadServiceLocations(this.provider.id,this.date)
}


   },
  watch: {
    provider: function (val) {

      if(val) {
        this.loadServiceLocations(val.id,this.date);

      }

    }
  },

    methods: {
      convertServiceLocationTypeToString: function (id) {
        let tempName
        if(id==="6dvgln4ofde"){
          tempName = this.$t("priceList.shuttle") 

        }
        if(id==="8ftpe6gpdvi"){
          tempName =  this.$t("priceList.valet") 

        }
        if(id==="hg49xy82wi"){
          tempName = "Park & Walk"

        }
        if(id==="lqbmkqreh"){
          tempName = "Park & Taxi"
        }
        return tempName
      },
      setTabID: function (id) {
      this.tabID= id
    },
    loadServiceLocations: function (providerId) {

      this.fetchServiceLocations(providerId,this.date).then((response) => {
        
         this.serviceLocations = response.data.provider.serviceLocations ?? []
         this.setTabID(this.serviceLocations[0].id)


      })

    }
    }
	}

</script>



